import { login } from '@/api/index'
import { getToken, setToken,getUserInfo,setUserInfo } from '@/utils/auth'

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    userInfo:getUserInfo()?JSON.parse(getUserInfo()):''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    setinfo:(state,info)=>{
      state.userInfo = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
       // // 去掉空格
      return new Promise((resolve) => {
        login(userInfo).then(res => {
         console.log(res,'>>res');
          const token = 'Bearer ' + res.data.token
          setToken(token)
          setUserInfo(JSON.stringify(res.data.user))
          commit('SET_TOKEN', token)
          commit('setinfo', res.data.user)
          resolve(res.data)
        }).catch(error => {
          console.log(error);
          resolve('error')
        })
      })
    }
  }
}

export default user
