import request from '../utils/request'
import { getUserInfo } from "../utils/auth";
const userinfo =getUserInfo()? JSON.parse(getUserInfo()):''

const getfile = (params) => request.post('/record/' + params[0] + '/sign', params[1], {
  headers: {
    'Content-Type': 'multipart/form-data;charset=utf-8'
  }
})
// 询问笔录pdf查看
const previewpdf = (params) => request.get('/record/' + params[0] + '/preview/pdf')
// 判断是否已签名
const getMsg = (id) => request.get(`/record/${id}`)

const getEvMsg = (id) => request.get(`/evidence/${id}`)

// 取证登录
const login = (params) => request.post('/auth/login', params)
// 获取登录人信息
const getCurrent = () => request.get('/user/curr')
// 获取人员列表
const getUserList = (params) => request.get('/user', { params })
// 上传取证相关信息
const uploadMsg = (params) => request.post('/evidence/collect', params, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
// 取证相关pdf查看
const previewAttest = (id) => request.get(`/evidence/${id}/preview/pdf`)
// 提交签名
const sumbitfile = (id, params) => request.put(`/evidence/${id}/sign`, params, {
  headers: {
    'Content-Type': 'multipart/form-data;charset=utf-8'
  }
})

export {
  getfile,
  previewpdf,
  login,
  getUserList,
  uploadMsg,
  previewAttest,
  sumbitfile,
  getCurrent,
  getMsg,
  getEvMsg
}

export function getEvidence(params) {
  return request({
    url: '/evidence',
    method: 'get',
    params: {
      userId:userinfo.id,
      pageNum: params[0],
      pageSize: params[1],
      keyword: params[2],
      statusList: params[3],
    }
  })
}

export function getEvidenceById(params) {
  return request({
    url: `/evidence/${params}`,
    method: 'get'
  })
}

export function sendMsg(params) {
  return request({
    url:'/sms/evidence/sign',
    method: 'get',
    params:{
      evidenceId:params[0],
      phone:params[1],
    }
  })
}

export function clearSign(params) {
  return request({
    url:'/evidence/clear/sign',
    method: 'get',
    params:{
      evidenceId:Number(params),
    }
  })
}
export function deleteEvidence(id) {
  return request({
    url:`/evidence/${id}`,
    method: 'DELETE',
  })
}

export function getpdf(id) {
  return request({
    url:`/evidence/${id}/export/pdf`,
    method: 'get',
    responseType: "blob",
  })
}
