import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const userInfo='user-info'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(userInfo)
}

export function setUserInfo(info) {
  return Cookies.set(userInfo, info)
}
