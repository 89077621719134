import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './style/main.scss'
import {
  Button, Card, Message, Dialog, Loading, Divider, Row, Col
} from 'element-ui';
import { Toast,Dialog as VantDialog  } from 'vant'
import VueSignaturePad from "vue-signature-pad";
import Vant from 'vant';
import 'vant/lib/index.css';
import './permission'
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
import vueEsign from 'vue-esign'
Vue.use(vueEsign)
Vue.use(infiniteScroll)
Vue.use(VueSignaturePad);
Vue.use(Vant);
Vue.use(Button).use(Card).use(Loading).use(Dialog).use(Divider).use(Row).use(Col)
Vue.prototype.$message = Message;
Vue.prototype.$moment = moment;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog=VantDialog
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
