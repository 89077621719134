import router from './router'
// import store from './store'
import { getToken } from '@/utils/auth'

const whiteList = ['/login', '/pdf']
// console.log('store :>> ', store.dispatch);
router.beforeEach((to, from, next) => {
  // console.log('getToken() :>> ', getToken());
  // console.log('to', to)
  if (getToken()) {
    /* has token*/
    // if (to.path === '/login') {
    //   next()
    // } else {
    next()
    // }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else if (to.path.indexOf('attest') !== -1) {
      next()
    } else {
      next('/login')
    }
  }
  // }

})

// router.afterEach(() => {
//   NProgress.done()
// })
