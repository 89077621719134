import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home') 
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: () => import('../views/pdf')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/evidence',
    name: 'Evidence',
    component: () => import('../views/evidence')
  },
  {
    path: '/evidence',
    name: 'Evidence',
    component: () => import('../views/evidence')
  },
  {
    path: '/attest/:id',
    name: 'Attest',
    component: () => import('../views/attest')
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
