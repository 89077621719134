import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Toast } from 'vant';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// axios.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded"
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://192.168.1.249:9000',
  // baseURL: 'https://child.terabits.cn:8088',
  // baseURL: "http://47.110.230.84:9003",
  baseURL: 'https://videoapi.terabits.cn',
  // baseURL: 'http://192.168.1.66:9003',
  // 超时
  // timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {

  // config.headers.authorization = localStorage.getItem(
  //   "token"
  // ) && localStorage.getItem(
  //   "token"
  // ) !== "undefined" ? `Bearer ${localStorage.getItem(
  //   "token"
  // )}` : null;
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  if (getToken()) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  // console.log('config :>> ', config);
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[key]) + '&';
            }
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // console.log('res', res)
  // 未设置状态码则默认成功状态
  const code = res.data.code || res.data.status || 200;
  // 获取错误信息
  const msg = res.data.message
  if (code !== 200 && code !== 1) {
    Toast({
      message: msg,
      type: 'fail'
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    // console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      // messages.error({ message: "后端接口连接异常" })
    }
    else if (message.includes("timeout")) {
      // messages.error({ message: "系统接口请求超时" })
    }
    else if (message.includes("Request failed with status code")) {
      // message = "系统接口" + message.substr(message.length - 3) + "异常";
      // messages.error({ message: "系统接口" + message.substr(message.length - 3) + "异常" })
      // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      //   confirmButtonText: '重新登录',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }
      // ).then(() => {
      //   // store.dispatch('FedLogOut').then(() => {
      //   //   location.href = '/login';
      //   // })
      // }).catch(() => { });
    }
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
